import '../css'
import './jquery.cycle2.cjs'
import './jquery.cycle2.center.cjs'

$(function() {
  $('#home-slideshow').cycle({
    centerHorz: true,
    centerVert: true,
    slides: '> a.cycle-slide'
  })
})

$(document).on('cycle-initialized', '#home-slideshow', function() {
  $('.cycle-slide').removeClass('hidden')
})

$(document).on('cycle-update-view', '#home-slideshow', function(e, optionHash, slideOptionsHash, currentSlide) {
  let caption = currentSlide.getAttribute('data-caption')
  if (caption) {
    $('#home-slideshow-caption').html(`<a href="${currentSlide.href}" class="btn btn-caption">${caption}</a>`)
  }
})
