import '../css'

const type = () => {
  let el = document.querySelector('#front_page_slide_linkable_real_type')
  if (!el) return ''
  return el.value
}

$(document).on('click', '.slide-modal-opener', function(e) {
  e.preventDefault()
  $('#show-slide a#link').attr('href', $(this).attr('data-link'))
  $('#show-slide img').attr('src', $(this).attr('data-image'))
  $('#show-slide #edit').attr('href', $(this).attr('data-path') + '/edit')
  $('#show-slide #delete').attr('href', $(this).attr('data-path'))
  $('#show-slide #caption').text($(this).attr('data-caption'))
  $('#show_slide').modal('show')
})

$(document).on('change', '#front_page_slide_linkable_real_type', () => $('#front_page_slide_linkable_id').val('').trigger('change'))

$(function() {
  $('#front_page_slide_linkable_id').select2({
    theme: 'bootstrap',
    minimumInputLength: 2,
    allowClear: true,
    placeholder: 'Select Link Type above, then type to search',
    ajax: {
      url: '/admin/front_page_slides/search-linkable',
      delay: 250,
      dataType: 'json',
      data: params => { return { type: type(), q: params.term } }
    }
  })
})
