import 'masonry-layout'

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
select2(window, $)

import '../css'
import './datetimepicker'
import './fontawesome'

$(function() {
  $('.select2').select2({
    theme: 'bootstrap',
    width: '100%',
    placeholder: 'None',
    allowClear: true
  })

  // loading="lazy" doesn't work reliably in Safari
  $('img[data-lazy]').each(function() {
    this.src = this.getAttribute('data-lazy')
    this.removeAttribute('data-lazy')
  })

  if (document.all && !document.addEventListener) // IE 8 or lower
    $('#browser-check').show()
})

$(document).on('click', '.disabled', function(e) {
  e.preventDefault()
})

$(document).on('select2:open', function() {
  document.querySelector('.select2-search__field').focus()
})
