import '../css'
import Galleria from 'galleria'

$(document).on('hidden.bs.modal', '#report_modal', function() {
  $(this).find('#report-form').removeClass('hidden')
  $(this).find('#report-sent').addClass('hidden')
})

$(document).on('keyup', '#report_content', function() {
  $('#new_report input[type="submit"]').toggleClass('disabled', $(this).val() == '')
})

$(document).on('click', '.photo-report-link, .video-report-link, .entry-report-link', function(e) {
  e.preventDefault()
  let el = this
  if (!this.hasAttribute('data-report-id')) {
    el = Galleria.get(0).getData().original
  }

  $('#report_reportable_id').val(el.getAttribute('data-report-id'))
  $('#report_content').val('')
  $('#report_modal').modal('show')
})
