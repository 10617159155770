import '../css'

import dragula from 'dragula'
import 'dragula/dist/dragula.css'
import Rails from '@rails/ujs'

const currentOrder = function() {
  return Array.from(document.querySelectorAll('li[data-id]')).map(el => el.getAttribute('data-id'))
}

$(function() {
  let container = document.getElementById('reorder')
  if (container) {
    let origOrder
    let drake = dragula([container])

    drake.on('drag', () => origOrder = currentOrder())

    drake.on('dragend', el => {
      let id = el.getAttribute('data-id')
      let ix = currentOrder().indexOf(id)
      if (ix == origOrder.indexOf(id)) return

      let data = new FormData()
      data.append('id', id)
      data.append('index', ix)

      Rails.ajax({
        url: container.getAttribute('data-url'),
        type: 'PATCH',
        dataType: 'script',
        data: data
      })
    })
  }
})
