import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCaretLeft, faCaretRight, faCaretDown, faPenToSquare, faMagnifyingGlass, faCamera, faVideo, faDownload, faUpload, faArrowsRotate, faFlag, faXmark, faCloud, faBars, faFolder, faBook, faAnchor, faBullhorn, faUsers, faTrophy, faTriangleExclamation, faCalendar, faUpDown, faBell } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan, faImage, faCopyright } from '@fortawesome/free-regular-svg-icons'

library.add(
  faCheck,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faPenToSquare,
  faMagnifyingGlass,
  faCamera,
  faVideo,
  faDownload,
  faUpload,
  faArrowsRotate,
  faFlag,
  faXmark,
  faCloud,
  faBars,
  faFolder,
  faBook,
  faAnchor,
  faBullhorn,
  faUsers,
  faTrophy,
  faTriangleExclamation,
  faCalendar,
  faUpDown,
  faBell,
  faTrashCan,
  faImage,
  faCopyright
)
dom.watch()
