/* eslint-disable */
import Galleria from 'galleria'
import $ from 'jquery'
import 'galleria/dist/themes/classic/galleria.classic.css'

export default {
  name: 'classic',
  version: 1.6,
  author: 'Galleria',
  css: 'galleria.classic.css',
  defaults: {
      transition: 'slide',
      thumbCrop:  'height',

      // set this to false if you want to show the caption all the time:
      _toggleInfo: true
  },
  init: function(options) {

      Galleria.requires(1.4, 'This version of Classic theme requires Galleria 1.4 or later');

      // add some elements
      this.addElement('info-link','info-close');
      this.append({
          'info' : ['info-link','info-close']
      });

      // cache some stuff
      var info = this.$('info-link,info-close,info-text'),
          touch = Galleria.TOUCH;

      // show loader & counter with opacity
      this.$('loader,counter').show().css('opacity', 0.4);

      // some stuff for non-touch browsers
      if (! touch ) {
          this.addIdleState( this.get('image-nav-left'), { left:-50 });
          this.addIdleState( this.get('image-nav-right'), { right:-50 });
          this.addIdleState( this.get('counter'), { opacity:0 });
      }

      // toggle info
      if ( options._toggleInfo === true ) {
          info.bind( 'click:fast', function() {
              info.toggle();
          });
      } else {
          info.show();
          this.$('info-link, info-close').hide();
      }

      // bind some stuff
      this.bind('thumbnail', function(e) {

          if (! touch ) {
              // fade thumbnails
              $(e.thumbTarget).css('opacity', 0.6).parent().hover(function() {
                  $(this).not('.active').children().stop().fadeTo(100, 1);
              }, function() {
                  $(this).not('.active').children().stop().fadeTo(400, 0.6);
              });

              if ( e.index === this.getIndex() ) {
                  $(e.thumbTarget).css('opacity',1);
              }
          } else {
              $(e.thumbTarget).css('opacity', this.getIndex() ? 1 : 0.6).bind('click:fast', function() {
                  $(this).css( 'opacity', 1 ).parent().siblings().children().css('opacity', 0.6);
              });
          }
      });

      var activate = function(e) {
          $(e.thumbTarget).css('opacity',1).parent().siblings().children().css('opacity', 0.6);
      };

      this.bind('loadstart', function(e) {
          if (!e.cached) {
              this.$('loader').show().fadeTo(200, 0.4);
          }
          window.setTimeout(function() {
              activate(e);
          }, touch ? 300 : 0);
          this.$('info').toggle( this.hasInfo() );
      });

      this.bind('loadfinish', function(e) {
          this.$('loader').fadeOut(200);
      });
  }
}
