import '../css'
import 'fancybox/dist/js/jquery.fancybox'
import 'fancybox/dist/css/jquery.fancybox.css'
import Galleria from 'galleria'
import theme from './galleria.classic'

$(function() {
  $('.fancybox').fancybox()

  let el = $('#galleria')
  if (el.length > 0) {
    // Galleria.loadTheme doesn't work with webpack so initialize manually
    let galleria = new Galleria().init(el, { height: 0.44 })
    galleria.theme = theme
    galleria._init.call(galleria)
    el.data('galleria', galleria)
  }
})

$(document).on('keydown', 'body.slideshow', function(e) {
  e.preventDefault()

  if (e.key == 'ArrowLeft')
    Galleria.get(0).prev()
  else if (e.key == 'ArrowRight')
    Galleria.get(0).next()
})

$(document).on('click', '#slide_in_new_window', function(e) {
  e.preventDefault()
  window.open(Galleria.get(0).getData().original.getAttribute('data-image-url'), '_blank')
})
