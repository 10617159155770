import '../css'

$(document).on('change', '#folder', function() {
  let folder = $(this).val()
  let url = new URL(document.location)

  if (folder == '') {
    url.searchParams.delete('folder')
  } else {
    url.searchParams.set('folder', $(this).val())
  }

  document.location = url
})
